import React from "react"
import { PageProps } from "gatsby"

import SEO from "../../components/seo"

import { GridContainer } from "../../components/grid"
import { SecondaryHero } from "../../components/heros"

const PaymentSucceededPage: React.FC<PageProps> = () => (
  <>
    <SEO title="Betaling Geslaagd" />
    <GridContainer>
      <SecondaryHero
        title="De betaling is geslaagd. Veel plezier!"
        subtitle="Je krijgt een e-mail binnen met..."
      ></SecondaryHero>
    </GridContainer>
  </>
)

export default PaymentSucceededPage
